import React, { useState, useRef } from 'react';
import { FeatureAccordionSectionData, FeatureTabStyle } from '@models/section/featureAccordionSection';
import { Grid2, Stack, Tab, Tabs, TabsActions } from '@mui/material';
import { CSSObject } from '@emotion/react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { RichTextContent } from '@components/RichTextContent';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { Picture } from '@components/Picture';
import { breakpoints, Heading, LazyLoadContainer, overlays, space, V2Button, v2Colors } from '@web-for-marketing/react-ui';
import { ParseAbbreviations } from '../ParseAbbreviations';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { MediaType } from './helpers/MediaType';
import { SectionContainer } from './SectionContainer';
import { Widget } from './Widget';

export interface FeatureAccordionProps {
    sectionData: FeatureAccordionSectionData;
    lazyLoad: boolean;
}

function getTabStyles(tabStyle: FeatureTabStyle, darkMode?: boolean): Record<string, CSSObject> {
    const defaultTabStyle = {
        '&.MuiTab-root': {
            textTransform: 'none',
            color: v2Colors.text.primary,
            fontWeight: 400,
            fontSize: '2rem !important',
            borderRadius: '8px',
            padding: '1.6rem 3.2rem',
            border: '1px solid transparent',
            transition:
                'background-color 500ms ease, border-color 500ms ease, color 500ms ease, font-weight 500ms ease',
            '&:not(:last-child)': {
                marginRight: '2rem',
            },
            '&:hover': {
                color: darkMode ? v2Colors.text.primary : v2Colors.text.activeSecondary,
                fontWeight: 500,
                background: darkMode ? overlays.active : overlays.activeSecondary,
                border: `1px solid ${v2Colors.action.active}`,
            },
            '&:focus-visible': {
                outline: `1px solid ${v2Colors.border.activePrimary}`,
                outlineOffset: '-1px',
            },
            [`@media (max-width: ${breakpoints.md})`]: {
                fontSize: '1.6rem !important',
                border: `1px solid ${v2Colors.border.primary}`,
                '&:not(:last-child)': {
                    marginRight: '1.6rem',
                },
            },
        },
        '&.Mui-selected': {
            color: darkMode ? v2Colors.text.primary : v2Colors.text.activeSecondary,
            fontWeight: 500,
            background: darkMode ? overlays.active : overlays.activeSecondary,
            border: `1px solid ${v2Colors.action.active}`,
        },
    } as const;

    const roundedTabStyle = {
        '&.MuiTab-root': {
            textTransform: 'none',
            color: v2Colors.text.activePrimary,
            fontSize: '2rem !important',
            fontWeight: 500,
            lineHeight: '1.3',
            borderRadius: '100px',
            padding: '1.2rem 3.6rem',
            zIndex: 100,
            '&:hover': {
                color: v2Colors.text.activeSecondary,
            },
            '&:focus-visible': {
                outline: `1px solid ${v2Colors.border.activePrimary}`,
                outlineOffset: '-1px',
            },
            [`@media (max-width: ${breakpoints.md})`]: {
                fontSize: '1.8rem !important',
                lineHeight: '1.4',
            },
        },
        '&.Mui-selected': {
            color: v2Colors.text.activeSecondary,
        },
    } as const;

    return {
        container: {
            margin: '4rem 0',
            border: tabStyle === 'rounded' ? `1px solid ${v2Colors.border.primary}` : 'none',
            borderRadius: tabStyle === 'rounded' ? '100px' : 0,
            padding: '8px 10px',
            maxWidth: '100%',
            backgroundColor: tabStyle === 'rounded' ? v2Colors.background.white : v2Colors.transparent,
            [`@media (max-width: ${breakpoints.md})`]: {
                marginRight: `-${space.containerOuterPadding}`,
                marginLeft: `-${space.containerOuterPadding}`,
                maxWidth: `calc(100% + (${space.containerOuterPadding} * 2))`,
                border: 'none',
            },
        },
        tab: tabStyle === 'rounded' ? roundedTabStyle : defaultTabStyle,
        indicator: {
            display: tabStyle === 'default' ? 'none' : 'block',
            height: '100%',
            backgroundColor: v2Colors.action.active,
            border: `1px solid ${v2Colors.action.active}`,
            borderRadius: '100px',
        },
    };
}

const classes = {
    tab: (tabStyle: FeatureTabStyle, darkMode?: boolean) => getTabStyles(tabStyle, darkMode),
    tabPanel: (hide: boolean) =>
        ({
            [`@media (min-width: ${breakpoints.md})`]: {
                transition: 'opacity 0.5s ease-in-out',
                opacity: hide ? 0 : 1,
            },
            padding: '0 1rem',
        }) as const,
    scrollButtons: {
        '&.Mui-disabled': {
            display: 'none',
        },
    },
    imageMedia: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
    },
    button: {
        '&.MuiButton-root': {
            display: 'inline-flex',
            alignItems: 'center',
            width: 'auto',
            [`@media (max-width: ${breakpoints.md})`]: {
                width: '100%',
            },
        },
    },
    contentContainer: {
        paddingRight: '6.4rem',
        [`@media (max-width: ${breakpoints.lg})`]: {
            paddingRight: '5.4rem',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            order: 2,
            paddingRight: 0,
        },
    },
    fullWidth: {
        width: '100%',
    },
    description: {
        marginTop: '2rem',
        textAlign: 'center',
        '& > ol, & > ul': {
            listStylePosition: 'inside',
        },
    },
} as const;

export function FeatureAccordion({ sectionData, lazyLoad }: FeatureAccordionProps): JSX.Element | null {
    const [isMobile] = useAtom(isMobileAtom);
    const [tab, setTab] = useState(0);
    const tabsRef = useRef<TabsActions>(null);
    const { title, description, featureTabs, tabStyle, darkOverlay } = sectionData;

    if (tab > featureTabs.length - 1) {
        setTab((prevTab) => prevTab - 1);
        tabsRef?.current?.updateIndicator();
    }

    function switchTabs(_event: React.SyntheticEvent, newTab: number): void {
        setTab(newTab);
    }

    function changeIndex(newTab: number): void {
        setTab(newTab);
    }

    return (
        <div
            data-testid='FeatureAccordionOverlay'
            css={{ overflowX: 'hidden', backgroundColor: darkOverlay ? v2Colors.background.light : undefined }}
        >
            <SectionContainer data-testid='FeatureAccordionTestID'>
                <Grid2 container direction='column' alignItems='center'>
                    <Heading align='center' variant='h2' css={classes.fullWidth}>
                        {title}
                    </Heading>
                    {description ? (
                        <RichTextContent content={description} css={[classes.description, classes.fullWidth]} />
                    ) : null}
                    <Tabs
                        action={tabsRef}
                        value={tab}
                        onChange={switchTabs}
                        css={classes.tab(tabStyle ?? 'default').container}
                        variant='scrollable'
                        scrollButtons={isMobile ? false : 'auto'}
                        TabScrollButtonProps={{
                            sx: classes.scrollButtons,
                        }}
                        TabIndicatorProps={{
                            sx: classes.tab(tabStyle ?? 'default').indicator,
                        }}
                    >
                        {featureTabs.map((featureTab, index) => (
                            <Tab
                                key={index}
                                label={featureTab.title}
                                id={`feature-accordion-tab-${index}`}
                                aria-controls={`feature-accordion-tabpanel-${index}`}
                                css={classes.tab(tabStyle ?? 'default', darkOverlay).tab}
                                disableRipple
                                wrapped
                            />
                        ))}
                    </Tabs>
                    <SwipeableViews
                        css={classes.fullWidth}
                        index={tab}
                        onChangeIndex={changeIndex}
                        springConfig={{
                            duration: '0s',
                            easeFunction: 'ease-out',
                            delay: '0s',
                        }}
                    >
                        {featureTabs.map(
                            ({ title, heading, description, buttons, media, widget, listStyle }, index) => {
                                const isHidden = tab !== index;
                                return (
                                    <div
                                        key={`${index}-${JSON.stringify(media)}`}
                                        role='tabpanel'
                                        data-testid={`tabpanel-${index}`}
                                        css={classes.tabPanel(isHidden)}
                                        id={`feature-accordion-tabpanel-${index}`}
                                        aria-labelledby={`feature-accordion-tab-${index}`}
                                    >
                                        <LazyLoadContainer
                                            component={Grid2}
                                            container
                                            alignItems='center'
                                            columnSpacing={6}
                                            rowSpacing={4}
                                            lazyload={lazyLoad}
                                        >
                                            <Grid2 size={{ xs: 12, md: 6 }} css={classes.contentContainer}>
                                                <Stack spacing='2.4rem'>
                                                    <Heading variant='h4' component='h3'>
                                                        {heading ? heading : title}
                                                    </Heading>
                                                    <RichTextContent
                                                        content={
                                                            ParseAbbreviations({
                                                                content: description,
                                                                stringFormat: true,
                                                            }) ?? ''
                                                        }
                                                        hideLinks={isHidden}
                                                        listStyle={listStyle}
                                                    />
                                                    {widget ? <Widget widget={widget} /> : null}
                                                    {buttons.length ? (
                                                        <Stack direction='row' gap={2}>
                                                            {buttons.map((button, index) =>
                                                                button.isPopout ? (
                                                                    <PopoutButton
                                                                        key={`${button.buttonText}-${index}`}
                                                                        text={button.buttonText}
                                                                        variant={button.buttonType || 'contained'}
                                                                        customSectionKey={button.popoutCustomSection}
                                                                        displayMode={button.popoutDisplayMode}
                                                                        backgroundImagePath={
                                                                            button.popoutBackgroundImage
                                                                        }
                                                                        color={button.buttonColor}
                                                                        aria-label={button.ariaLabel}
                                                                        css={classes.button}
                                                                        params={button.params}
                                                                        tabIndex={isHidden ? -1 : 0}
                                                                        isV2
                                                                    />
                                                                ) : (
                                                                    <V2Button
                                                                        key={`${button.buttonText}-${index}`}
                                                                        component='a'
                                                                        variant={button.buttonType || 'primary'}
                                                                        color={button.buttonColor}
                                                                        href={button.buttonLinkPath}
                                                                        target={getLinkTargetValue(button.buttonTarget)}
                                                                        aria-label={button.ariaLabel}
                                                                        css={classes.button}
                                                                        tabIndex={isHidden ? -1 : 0}
                                                                    >
                                                                        {button.buttonText}
                                                                    </V2Button>
                                                                )
                                                            )}
                                                        </Stack>
                                                    ) : null}
                                                </Stack>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12, md: 6 }}>
                                                {media.type === 'video' ? (
                                                    <MediaType content={media.content} lazyLoad={lazyLoad} />
                                                ) : media.type === 'image' ? (
                                                    <Picture
                                                        imagePath={media.content.imagePath}
                                                        mobilePath={media.content.mobilePath}
                                                        alt={media.content.imageAlt}
                                                        mobileAlt={media.content.mobileAlt}
                                                        css={classes.imageMedia}
                                                        lazyLoad={lazyLoad}
                                                        fullWidth
                                                        preload={index === 0 && !lazyLoad}
                                                        aspectRatio={media.content.imageAspectRatio}
                                                        mobileAspectRatio={media.content.mobileImageAspectRatio}
                                                    />
                                                ) : null}
                                            </Grid2>
                                        </LazyLoadContainer>
                                    </div>
                                );
                            }
                        )}
                    </SwipeableViews>
                </Grid2>
            </SectionContainer>
        </div>
    );
}
