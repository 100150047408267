import React, { useState } from 'react';
import { CapterraBannerSectionData, CapterraBannerTheme } from '@models/section/capterraBannerSection';
import { Grid2, Stack } from '@mui/material';
import { SectionContainer } from './SectionContainer';
import { Text, v2Colors } from '@web-for-marketing/react-ui';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { CapterraLogo } from '@icon/CapterraLogo';
import { CapterraLogoDark } from '@icon/CapterraLogoDark';
import { RatingSelect } from '@components/BlogSection/BlogComponents/Shared/RatingSelect';
import { capterraTestimonialStyles as classes } from './styleObjects/CapterraBannerStyles';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';

export interface CapterraBannerProps {
    sectionData: CapterraBannerSectionData;
}

export function CapterraBanner({ sectionData }: CapterraBannerProps): JSX.Element {
    const [activeStep, setActiveStep] = useState(0);
    const [isMobile] = useAtom(isMobileAtom);
    const testimonialListArray = [];
    const { theme, overallRating, testimonials } = sectionData;
    const darkTheme = theme === CapterraBannerTheme.Dark;
    const cardsPerView = isMobile ? 1 : 3;

    if (testimonials.length > cardsPerView) {
        for (let i = 0; i < testimonials.length; i += cardsPerView) {
            const testimonialCards = testimonials.slice(i, i + cardsPerView);
            testimonialListArray.push(testimonialCards);
        }
    } else {
        testimonialListArray.push(testimonials);
    }

    const maxSteps = testimonialListArray.length > 0 ? testimonialListArray.length : 1;
    const dotsArray = Array.from(Array(maxSteps).keys());

    if (activeStep > maxSteps - 1) {
        setActiveStep(activeStep - 1);
    }

    function handleStepChange(step: number): void {
        setActiveStep(step);
    }

    return (
        <div css={darkTheme ? classes.darkBackground : classes.lightBackground}>
            <SectionContainer data-testid='CapterraBannerSection' spacing='4.8rem'>
                <Grid2 container spacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid2
                        size={{ xs: 12, sm: 5, md: 3 }}
                        justifyItems={{ xs: 'flex-start', sm: 'center' }}
                        alignSelf={{ sm: 'center' }}
                    >
                        <Stack alignItems={'center'}>
                            {darkTheme ? (
                                <CapterraLogoDark
                                    color={'transparent'}
                                    style={classes.cardLogo}
                                    data-testid='CapterraLogoDark'
                                />
                            ) : (
                                <CapterraLogo
                                    color={'transparent'}
                                    style={classes.cardLogo}
                                    data-testid='CapterraLogo'
                                />
                            )}
                            <Stack spacing={2} direction={'row'} alignItems={'center'} css={classes.overallRating}>
                                <Text
                                    variant={'body1'}
                                    weight={'bold'}
                                    color={darkTheme ? 'inversePrimary' : 'primary'}
                                >
                                    {overallRating}
                                </Text>
                                <RatingSelect
                                    rating={overallRating}
                                    starColor={darkTheme ? v2Colors.action.white : v2Colors.action.mustard}
                                    starSize={20}
                                />
                            </Stack>
                            <div css={classes.pagination}>
                                {dotsArray.length > 1 ? (
                                    <Grid2 css={classes.dotsContainer} data-testid='dots-container'>
                                        {dotsArray.map((dot: number) => {
                                            const isActive = dot === activeStep;

                                            return (
                                                <button
                                                    key={dot}
                                                    css={classes.dotButton}
                                                    onClick={() => handleStepChange(dot)}
                                                    aria-label={`Go to slide ${dot + 1}`}
                                                    tabIndex={isActive ? -1 : 0}
                                                >
                                                    <div
                                                        css={[
                                                            classes.dot(darkTheme),
                                                            isActive ? classes.dotActive(darkTheme) : null,
                                                        ]}
                                                    />
                                                </button>
                                            );
                                        })}
                                    </Grid2>
                                ) : null}
                            </div>
                        </Stack>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 7, md: 9 }}>
                        <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                            {testimonialListArray.map((testimonialList, index) => (
                                <Grid2 container direction={'row'} key={index} spacing={{ xs: 1, md: 4 }}>
                                    {testimonialList.map((testimonial, index) => (
                                        <Grid2 key={index} size={{ xs: 12, md: 4 }} data-testid='CapterraCard'>
                                            <div css={{ marginTop: '3px' }}>
                                                <RatingSelect
                                                    rating={testimonial.rating}
                                                    starColor={
                                                        darkTheme ? v2Colors.action.white : v2Colors.action.mustard
                                                    }
                                                    starSize={20}
                                                />
                                            </div>
                                            <div css={classes.testimonialQuote}>
                                                <Text
                                                    variant='body2'
                                                    color={darkTheme ? 'inversePrimary' : 'secondary'}
                                                >
                                                    {testimonial.quote}
                                                </Text>
                                            </div>
                                            <div css={classes.authorContainer}>
                                                <Text
                                                    variant='body2'
                                                    color={darkTheme ? 'inversePrimary' : 'primary'}
                                                    weight='bold'
                                                >
                                                    {`${testimonial.authorName}, ${testimonial.authorRole}`}
                                                </Text>
                                                <Text variant='body2' color={darkTheme ? 'inversePrimary' : 'tertiary'}>
                                                    {testimonial.industry}
                                                </Text>
                                            </div>
                                        </Grid2>
                                    ))}
                                </Grid2>
                            ))}
                        </SwipeableViews>
                        <div css={classes.paginationMobile} data-testid='CarouselDotMobile'>
                            {dotsArray.length > 1 ? (
                                <Grid2 css={classes.dotsContainer} data-testid='dots-container'>
                                    {dotsArray.map((dot: number) => {
                                        const isActive = dot === activeStep;

                                        return (
                                            <button
                                                key={dot}
                                                css={classes.dotButton}
                                                onClick={() => handleStepChange(dot)}
                                                aria-label={`Go to slide ${dot + 1}`}
                                                tabIndex={isActive ? -1 : 0}
                                            >
                                                <div
                                                    css={[
                                                        classes.dot(darkTheme),
                                                        isActive ? classes.dotActive(darkTheme) : null,
                                                    ]}
                                                />
                                            </button>
                                        );
                                    })}
                                </Grid2>
                            ) : null}
                        </div>
                    </Grid2>
                </Grid2>
            </SectionContainer>
        </div>
    );
}
