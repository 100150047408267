import React, { useState } from 'react';
import { VerticalAccordionSectionData } from '@models/section/verticalAccordionSection';
import { Grid } from '@mui/material';
import { Picture } from '@components/Picture';
import { RichTextContent } from '@components/RichTextContent';
import {
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionTrigger,
    ForwardIcon,
    Heading,
    LazyLoadContainer,
    Text,
    V2Button,
} from '@web-for-marketing/react-ui';
import { SectionContainer } from './SectionContainer';

export interface VerticalAccordionProps {
    sectionData: VerticalAccordionSectionData;
    headerSection: boolean;
    lazyLoad?: boolean;
}

const classes = {
    fullWidth: {
        width: '100%',
    },
    imageContainer: {
        position: 'relative',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
    },
    image: (show: boolean) => {
        const imageStyle = {
            borderRadius: '1rem',
            transition: 'opacity 0.5s ease-in-out',
        };

        if (show) {
            return {
                ...imageStyle,
                opacity: 1,
            } as const;
        } else {
            return {
                ...imageStyle,
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
            } as const;
        }
    },
} as const;

export function VerticalAccordion({ sectionData, headerSection, lazyLoad }: VerticalAccordionProps): JSX.Element {
    const [activeAccordionIndex, setActiveAccordionIndex] = useState([0]);
    const { title, description, accordionItems, swapSides, accordionButtonVariant } = sectionData;

    return (
        <SectionContainer>
            <Grid container direction='column' rowGap={{ xs: 2, md: 3 }}>
                {title ? (
                    <Heading align='center' variant={headerSection ? 'h1' : 'h2'} css={classes.fullWidth}>
                        {title}
                    </Heading>
                ) : null}
                {description ? (
                    <Text align='center' css={classes.fullWidth}>
                        {description}
                    </Text>
                ) : null}
                <Grid
                    container
                    direction={swapSides ? { xs: 'row', md: 'row-reverse' } : 'row'}
                    spacing={{ xs: 2, md: 5 }}
                >
                    <Grid item md={7} xs={12}>
                        <LazyLoadContainer component={Grid} lazyload={lazyLoad} css={classes.imageContainer}>
                            {accordionItems.map((accordionItem, index) => (
                                <Picture
                                    key={index}
                                    imagePath={accordionItem.image.imagePath}
                                    alt={accordionItem.image.imageAlt}
                                    mobilePath={accordionItem.image.mobilePath}
                                    mobileAlt={accordionItem.image.mobileAlt}
                                    aspectRatio={accordionItem.image.imageAspectRatio}
                                    mobileAspectRatio={accordionItem.image.mobileImageAspectRatio}
                                    lazyLoad={lazyLoad}
                                    css={classes.image(activeAccordionIndex.includes(index))}
                                />
                            ))}
                        </LazyLoadContainer>
                    </Grid>
                    <Grid item gap={1} md={5} xs={12}>
                        <Accordion
                            value={activeAccordionIndex}
                            css={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                        >
                            {accordionItems.map((accordionItem, index) => (
                                <AccordionItem key={index}>
                                    <AccordionTrigger
                                        headingLevel={!headerSection && title ? 'h3' : 'h2'}
                                        hideIcon
                                        onClick={() => {
                                            setActiveAccordionIndex([index]);
                                        }}
                                    >
                                        {accordionItem.title}
                                    </AccordionTrigger>
                                    <AccordionPanel>
                                        <div css={classes.accordionDetails}>
                                            <RichTextContent content={accordionItem.description} color='secondary' />
                                            {accordionItem.button.buttonText ? (
                                                <V2Button
                                                    variant={accordionButtonVariant ?? 'link'}
                                                    rightIcon={<ForwardIcon />}
                                                    component='a'
                                                    href={accordionItem.button.buttonLinkPath}
                                                    target='_blank'
                                                    className={accordionItem.button.buttonClassName}
                                                >
                                                    {accordionItem.button.buttonText}
                                                </V2Button>
                                            ) : null}
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </SectionContainer>
    );
}
